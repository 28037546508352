import 'what-input'

$(() => {
  const syosContainer = document.querySelector('.tn-syos')

  if (syosContainer) {
    $('body').addClass('tn-syos-active')
  }

  // Open Menu
  //
  let scrollPosition = $(window).scrollTop()
  const toggleMobileMenu = function () {
    if (!$('body').hasClass('nav-open')) {
      scrollPosition = $(window).scrollTop()
    }

    $('body').toggleClass('nav-open nav-closed')
    $('html').toggleClass('nav-open')

    $('html, body').scrollTop(scrollPosition)
  }
  $('.c-navigation-trigger').on('click', toggleMobileMenu)

  /**
   * Make Marketing Preferences Mandatory
   */
  // Store HTML in vars
  const requiredLabel = '<small aria-hidden="true" class="tn-required-field pull-right">Required</small>'
  const requiredErrorMessage = '<div class="help-block">This field is required.</div>'

  // Function to make marketing preferences mandatory
  const mandatoryMarketingPref = () => {
    // Check if last unique element has loaded in DOM
    if (!$('#4-1').length) {
      // Re-call function if element has failed to load
      window.setTimeout(mandatoryMarketingPref, 500)
      return
    }
    // Add "Required" label to fields
    $('#contactPermissionsApp fieldset').prepend(requiredLabel)
    // On load disable form submit btn
    $('#tn-account-register-button-register').prop('disabled', true)

    // Check if Marketing Preferences have been selected
    // If so, Submit Btn is enabled
    // Target Input Radio Buttons
    $('#1-0, #1-1, #4-0, #4-1').click(() => {
      // Check if any error messages/styling have been applied and remove
      if (($('#1-0').prop('checked') || $('#1-1').prop('checked')) && $('#1-0').parents('fieldset').hasClass('has-error')) {
        removeErrorMessage('#1-0')
      }
      if (($('#4-0').prop('checked') || $('#4-1').prop('checked')) && $('#4-0').parents('fieldset').hasClass('has-error')) {
        removeErrorMessage('#4-0')
      }
      // Enable Submit Btn if preferences have been set
      if (($('#1-0').prop('checked') || $('#1-1').prop('checked')) && ($('#4-0').prop('checked') || $('#4-1').prop('checked'))) {
        $('#tn-account-register-button-register').prop('disabled', false)
      }
    })

    // Present Error Message If Submit Btn Is Clicked
    $('#tn-account-register-button-register').parent().click(() => {
      // Check if Email Preferences have been selected
      if ((!$('#1-0').prop('checked') && !$('#1-1').prop('checked')) && !$('#1-0').parents('fieldset').hasClass('has-error')) {
        addErrorMessage('#1-0')
      }
      // Check if Post Preferences have been selected
      if ((!$('#4-0').prop('checked') && !$('#4-1').prop('checked')) && !$('#4-0').parents('fieldset').hasClass('has-error')) {
        addErrorMessage('#4-0')
      }
    })
  }

  // Function to remove error messaging/styling
  const removeErrorMessage = (element) => {
    $(element).parents('fieldset').removeClass('has-feedback has-error')
    $(element).parents('fieldset').children('.help-block').remove()
    $(element).parents('fieldset').children('.tn-component__fieldset-label').removeClass('control-label')
  }

  // Function to add error messaging/styling
  const addErrorMessage = (element) => {
    $(element).parents('fieldset').append(requiredErrorMessage).addClass('has-feedback has-error')
    $(element).parents('fieldset').children('.tn-component__fieldset-label').addClass('control-label')
  }

  // Call function to make selecting marketing preferences mandatory
  mandatoryMarketingPref()
})

// Append venue to body class
//
if ($(".tn-event-detail__location:contains('Guildhall')").length > 0) {
  $('body').addClass('state--venue-guildhall')
}

/*
 * Add body class to determine seatmap background
 */

// Options for the observer (which mutations to observe)
const config = { attributes: true, childList: true, subtree: true }

// Callback function to execute when mutations are observed
const callback = function (mutationsList, observer) {
  // Use traditional 'for loops' for IE 11
  const $body = $('body')

  for (const mutation of mutationsList) {
    // Check for seat ID within each seatmap layout
    if (document.querySelector('.tn-syos-seat-map__seat[data-tn-seat-id="519740"]') && mutation.type === 'childList') {
      // If so then add body class, and remove other body classes if required
      $body.addClass('seatmap--587')
    }
  }
}

function addObserverIfDesiredNodeAvailable () {
  const targetNode = document.querySelector('.tn-syos')
  if (!targetNode) {
    // The node we need does not exist yet.
    // Wait 500ms and try again
    window.setTimeout(addObserverIfDesiredNodeAvailable, 500)
    return
  }
  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback)

  // Start observing the target node for configured mutations
  observer.observe(targetNode, config)
}
addObserverIfDesiredNodeAvailable()
